import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import CTAButton from "../components/cta-button"
import ImgAboutTravel from "../img/about-travel"
import ImgAboutWorkdesk from "../img/about-workdesk"
import ImgAboutTalk from "../img/about-talk"
import ImgCoffee from "../img/coffee"

const AboutPage = () => (
    <Layout>
        <SEO title="About" description="Get to know Nadzim, a freelance web designer from Kuala Lumpur."/>
        <div className="cols-1-1">
            <div>
                <h3 class="h3-on-h1">About</h3>
                <h1>I simply want to help entrepreneurs like you achieve big dreams.</h1>
                <p>Starting and running a business is never easy. I understand. Having to build and run a good website is a whole other thing business owners worry about. You don’t have to.</p>
                <p>Let me create a great website for you so you can focus to grow and work towards your dreams.</p>
            </div>
        </div>
        <div className="cols-1-2 section">
            <div></div>
            <div>
                <ImgAboutTravel/>
                <p><em>My dream is to take photos around the globe. What’s yours?</em></p>
            </div>
        </div>
        <div class="section">
            <div className="cols-1-1">
                <div>
                    <h1>Growing businesses and communities.</h1>
                    <p>I spend my time and energy on specific efforts to help entrepreneurs grow their businesses sustainably.</p>
                </div>
            </div>
            <ul className="cols-1-1-1" id="about-skills">
                <li>
                    <h3>Visual user experience design</h3>
                    <p>Designs that look good is never enough. I focus on sparking interest in customers and lead them to take action (buy from you).</p>
                </li>
                <li>
                    <h3>Search engine optimization (SEO)</h3>
                    <p>I help websites rank well on Google searches by optimizing every pixel for performance and mobile friendliness.</p>
                </li>
                <li>
                    <h3>Knowledge sharing</h3>
                    <p>I actively contribute to online communities to help entrepreneurs learn to manage and make the best use of their websites.</p>
                </li>
            </ul>
        </div>
        <div className="cols-1-2">
            <div></div>
            <ImgAboutWorkdesk/>
        </div>
        <div className="cols-1-1 section">
            <div></div>
            <div>
                <h3 class="h3-on-h1">#Throwback</h3>
                <h1>I started to “design” websites in Microsoft Paint</h1>
                <p>I got hooked into web design since we got our first PC running Windows 98. I had a lot of fun pretending to design Dragon Ball websites in MS Paint.</p>
                <p>It was a stupid hobby but it kicked-off my web design journey from ancient Geocities and Flash pages to modern, corporate websites and eCommerce platforms. My websites now generate actual income for real businesses.</p>
                <p>Fast forward to university though...</p>
            </div>
        </div>
        <div className="cols-1-1 section">
            <div>
                <h3 class="h3-on-h1">Solving problems</h3>
                <h1>How Engineering evolved me as a web designer.</h1>
                <p>You know the typical Malaysian boy mindset? I got myself an engineering degree and I’m making a pretty cool career out of it. But I used to think engineering is the end of my web design journey. I was so wrong. <strong>It became my best asset.</strong></p>
                <p>Engineering taught me how to effectively work with companies to solve technical and commercial problems and achieve big goals. It’s a lesson no design course can ever truly teach.</p>
            </div>
        </div>
        <div className="cols-2-1 section">
            <div>
                <ImgAboutTalk/>
                <p><em>My session during an Society of Petroleum Engineers Asia Pacific event.</em></p>
            </div>
        </div>
        <div className="section section-highlight">
            <div className="cols-1">
                <p>I no longer just make pretty things in a browser.</p>
                <h1>Web design became a machine for me to help entrepreneurs reach amazing goals.</h1>
            </div>
        </div>
        <div className="cols-1-1 section">
            <div>
                <h1>Why Nadzim.com?</h1>
                <p>A good website is, more than ever before, an <strong>important</strong> component to build a successful business in 2023.</p>
                <p>I put up this Nadzim.com website so entrepreneurs like you would know a <strong>world-class website for your business is within your reach.</strong></p>
                <p>You don’t have to choose between years of <strong>learning</strong> programming codes or <strong>settling</strong> for a ‘template’ website made by people who don’t give a damn.</p>
                <p>Let’s work together and create an amazing website that gets you <strong>high-value</strong> customers, <strong>grow</strong> your business, and realize your <strong>dreams.</strong></p>
                <p><strong>Why not start today?</strong></p>
                <CTAButton url="/contact">Start a website</CTAButton>
                <p><Link to="/packages">View pricing</Link></p>
            </div>
            <ImgCoffee/>
        </div>
    </Layout>
)

export default AboutPage